import React from "react";
import Hero from "../components/Studio/Hero";
function Studio() {
  return (
    <div className="">
      <Hero />
    </div>
  );
}

export default Studio;
